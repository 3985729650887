import { Routes, Route, useLocation } from "react-router-dom";

import Art from "../pages/Art";
import Overview from "../pages/Overview";
import Photography from "../pages/Photography";

const AnimateRoutes = () => {
    
    return <Routes>
        {/* <Route path="/" element={<Overview />} /> */}
        {/* <Route path="/art" element={<Art />} /> */}
        <Route path="/" element={<Photography />} />
    </Routes>
}

export default AnimateRoutes;