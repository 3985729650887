interface Dictionary {
    [x: string]: string;
  };
  

export const imageConfig: Dictionary[] = [
    {full_size: "R6II5628_v3_small.jpg", subject: "SPERM WHALE", location: "Andenes, Norway"},
    {full_size: "R6II8098_small.jpg", subject: "SEA EAGLE", location: "Trollfjord, Norway"},
    {full_size: "R6II0720_small.jpg", subject: "ARCTIC TERN", location: "Liland, Norway"},
    {full_size: "R6II4469_small.jpg", subject: "GREY HERON", location: "Liland, Norway"},
    {full_size: "R6II9942_small.jpg",  subject: "ARCTIC TERN", location: "Liland, Norway"},
    {full_size: "R6II9037_v3_small.jpg", subject: "LONE YELLOW RORBU", location: "Sakrisøy, Norway"},
    {full_size: "R6II9347_small.jpg",  subject: "YELLOW RORBUER", location: "Sakrisøy, Norway"},
    {full_size: "IMG_3334_small.jpg", subject: "ARCHWAY OF VENICE", location: "Venice, Italy"},
    {full_size: "IMG_3456_small.jpg", subject: "ALLEYWAY OF VENICE", location: "Venice, Italy"},
    {full_size: "IMG_2963_small.jpg", subject: "WATERWAY OF VENICE", location: "Venice, Italy"},
]
