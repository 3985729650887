import { Link } from "react-router-dom";

import MobileNav from "./MobileNav";

const Header = () => {
    // return <header className="bg-pink-200 fixed w-full">Nadia Miah</header>;
    return (
        <div className="header">
            <Link style={{ textDecoration: 'none' }} to={"/"}><h1>NADIA MIAH</h1></Link>
            <span className="invisible md:visible full-text">
                <Link style={{ textDecoration: 'none' }} to={"/"}><h2>PHOTOGRAPHY</h2></Link>
                {/* <Link style={{ textDecoration: 'none' }} to={"/art"}><h2>ART</h2></Link> */}
            </span>
            <span className="short-text"><MobileNav/></span>
        </div>
    )
}

export default Header;