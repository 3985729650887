import React, { useState } from 'react';

import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";

import AnimateRoutes from "./components/AnimateRoutes";
import Header from "./components/Header";
import Modal from "./components/Modal";
// import ImageGrid from "./components/imageGrid";


function App() {
  // const [selectedImg, setSelectedImg] = useState(null);

  return (
    <div className="App">
      <Router>
        <Header />
        <AnimateRoutes />
        {/* { selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )} */}
      </Router>
    </div>
  );
}

export default App;
